@tailwind base;
@tailwind components;
@tailwind utilities;

@import './tabledata.bootstrap.scss';
@import './_global-colors.scss';

@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon/icomoon.eot?casf8t');
  src: url('../assets/fonts/icomoon/icomoon.eot?casf8t#iefix') format('embedded-opentype'),
  url('../assets/fonts/icomoon/icomoon.ttf?casf8t') format('truetype'),
  url('../assets/fonts/icomoon/icomoon.woff?casf8t') format('woff'),
  url('../assets/fonts/icomoon/icomoon.svg?casf8t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'],
.ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-upload:before {
  content: "\e913";
}

.icon-xls-file:before {
  content: "\e912";
  @apply text-dark;
}

.icon-user-plus:before {
  content: "\e911";
}

.icon-scan:before {
  content: "\e910";
}

.icon-people:before {
  content: "\e90f";
}

.icon-info:before {
  content: "\e909";
}

.icon-eye:before {
  content: "\e90d";
}

.icon-pen:before {
  content: "\e908";
  color: var(--purple);
}

.icon-pen-inherit:before {
  content: "\e908";
  color: inherit;
}

.icon-trashcan:before {
  content: "\e90e";
  color: var(--purple);
}

.icon-cash:before {
  content: "\e90a";
}

.icon-withdrawal:before {
  content: "\e90b";
}

.icon-dollar:before {
  content: "\e90c";
}

.icon-file:before {
  content: "\e907";
}

.icon-filter:before {
  content: "\e901";
}

.icon-collapse:before {
  content: "\e902";
}

.icon-users:before {
  content: "\e900";
}

.icon-rdc:before {
  content: "\e903";
}

.icon-kyc:before {
  content: "\e904";
}

.icon-wires:before {
  content: "\e905";
}

.icon-accounts:before {
  content: "\e906";
}

.icon-chart:before {
  content: "\e99b"
}
.icon-clone:before {
  content: "\2750";
  color: var(--purple);
}
