$ionfi-purple: #4E00C6;
$ionfi-teal: #00989F;
$bright-gray: #F8F8FA;

$ionfi-black-opacity-9: rgba(24, 24, 25, 0.9);
$ionfi-black-opacity-5: rgba(24, 24, 25, 0.5);
$ionfi-black-opacity-7: rgba(24, 24, 25, 0.7);
$ionfi-black-opacity-0-16: rgba(86, 87, 88, 0.16);
$ionfi-black-opacity-0-42: rgba(24, 24, 25, 0.42);

$ionfi-input-border: #ced4da;
