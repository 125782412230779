/*!**/
/*bootstrap table theme*/
/**!*/
/*.ngx-datatable.bootstrap {*/
/*box-shadow: none;*/
/*font-size: 13px;*/
/*}*/

@import "./_global-colors.scss";

.ngx-datatable.bootstrap:not(.cell-selection)
.datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
.datatable-body-row:hover
.datatable-row-group {
  background: var(--border) !important;
  cursor: default;
}

.ngx-datatable.bootstrap .datatable-header {
  border-bottom: 1px solid var(--border);
  font-size: 14px;
  color: var(--text-primary);
}

.ngx-datatable.bootstrap .empty-row {
  color: var(--text-black-opacity-7);
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell:last-child {
  text-align: end !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-cell:last-child {
  text-align: end !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  /*vertical-align: bottom;*/
  padding: 0.75rem;
  /*background-color: #d1d4d7;*/
  background-color: transparent;
  border: none !important;
  color: $ionfi-black-opacity-7;
  font-weight: 500;
  /*border-bottom: 2px solid #d1d4d7;*/
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  color: var(--text-black-opacity-7);
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-cell {
  overflow: initial !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  /*vertical-align: top;*/
  height: unset !important;
  border-top: none !important;
  border-bottom: 1px solid var(--border);
  color: var(--text-black-opacity-9);
  font-size: 14px;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  /*background-color: rgba(0, 0, 0, 0.03);*/
  background-color: transparent;
}

/*.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {*/
/*background-color: #1483ff;*/
/*color: #FFF;*/
/*}*/

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.75rem;
  height: unset !important;
}

.datatable-body-cell a:hover {
  color: var(--purple) !important;
}

.ngx-datatable.bootstrap {
  &.no-vh-calc .datatable-footer {
    margin-top: 0;
    position: relative;
    bottom: 0 !important;
  }
  //.datatable-footer {
  //  margin-top: -1px;
  //  position: absolute;
  //  bottom: 20px;
  //}
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
  font-size: 0.75em;
  flex: 1 1 40%;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 10px 0px;
  font-size: 14px;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  /*background-color: #545454;*/
  background: rgba(86, 87, 88, 0.12);
  border-radius: 4px;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  text-decoration: none;
  /*color: #ededed;*/
}

.ngx-datatable .datatable-body {
  width: auto !important;
  overflow: auto;
}

datatable-body-row .datatable-row-center {
  align-items: center;
  label {
    margin-bottom: 0;
  }
}

.datatable-body .empty-row {
  text-align: center;
  padding: 15px;
  color: rgba(24, 24, 25, 0.7);
}

.datatable-scroll {
  width: fit-content !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}

.ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

.ngx-datatable.bootstrap .datatable-body-row.active{
  background: #EEE6FA !important;
  }

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  border-radius: 3px;
  border: 1px solid #E6E7E9;
  &.pages {
    margin: 2px;
  }
  a {
    color: var(--purple) !important;
  }
  &:not(.disabled).active a {
    margin: 0;
    background: rgba(78, 0, 198, 0.08);
  }
}


